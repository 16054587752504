.backgroundPrincipal{
    background-image: url('../../img/backgroundPrincipal-min.jpg');
    -webkit-filter:brightness(0.90);
            filter:brightness(0.90);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
    overflow: hidden;
}
.titleMain{
    padding: 100px;
    padding-bottom: 0px;
    text-align: center;
}

.textMain{
    color:white;
    text-align: center;
}


.alertOfferBG{
    background-color: transparent;
    border-right: 1px solid white;
    border-left: 1px solid white;
    border-radius: 20px;
    height: 30px;
    z-index:10;
    position: absolute;
    width: 60vw;
    overflow: hidden;
    top: 0px;
    left: 0px;
    right: 0px;
    margin:auto;
    background-color: rgba(255, 255, 255, 0.15);
 }

    .offerMain{
        z-index: 7;
        position: absolute;
        width:-webkit-max-content;
        width:-moz-max-content;
        width:max-content;
    }
#arrowDownScroll{
        position: absolute;
        margin: auto;
        left: 0px;
        right: 0px;
        bottom: 0px;
        cursor: pointer;

    }

   