
.sectionEntrep{
    background-color: #070000;
    background-image: url('../../../img/backgroundEmp.png');
    background-repeat: no-repeat;
    background-attachment: cover;
    background-position: right;
    overflow: hidden;

}
.bodySectionEntrep{
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 50px;
}
.titleEntrep{
    padding: 2vw;
    border-bottom: 1px dashed white;
}
.bodyEntrep{
    text-align: center;
}
.listEntrep{
    padding-top: 20px;
    text-align: left;
}
.listEntrep > li{
    color:white;
    margin-top: 10px;
    list-style: none;

}
.rowListEntrep::before{
    content:'> ';
    color:grey;
}

.buttonPrev, .buttonNext{
    background-color: transparent;
    color:white;
    border:none;
    align-items: center;
    position: static;
}
.buttonPrev{
}
.buttonNext{
}
#imgEntrep{
    height: 180px;
    width: 200px;
    opacity: 0.5;
    margin-top: 25px;
    align-self: center center;
}

.reasonsWeb{
    position: flex;
    text-align: center;
}
.reasonsWithImg{
    display: inline-block;
    text-align: center;
    color:orange;
}