.me{
    background-color: black;
    color: white;
}
.titleMe{
    text-align: center;
    padding: 2vw;
    background-color: black;
    margin-bottom: 0;
}

.menu{
    display: inline-block;
    list-style: none;
    padding: 10px;
}
.menu > button{
    background-color: black;
    color:white;
    border:1px solid white;
    margin:10px;
    padding: 10px;
}
.bodyMe{
    text-align: center;
}

.descriptionMe{
    margin:30px auto;
    text-align: center;
    align-items: center;
    letter-spacing: 1px;
    width: 80vw;
}
.buttonMe:hover{
    border:1px solid orange;
}

td{
    padding-right: 50px;
    text-align: center;
}
tr > td{
    
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.414);
}

.buttonHideShow{
    background-color: transparent;
    color: orange;
    font-size: 30px;
    border: none;
}
.titlesAboutme{
    text-align: left;
}
.cardPortfolio{
    border:1px solid white;
    height: calc(100vw/6);
    margin: 20px;
}