// FONT ORDENADOR
.fontL{
    font-size: 32px;
    font-weight: 900;
}

.fontM{
    font-size: 22px;
}

.fontS{
    font-size: 18px;
    line-height: 20px;
}
.fontXS{
    font-size: 14px;
}


// FONTS MOVILES
@media screen and (max-width:767px){

.fontL{
    font-size: 32px;
}

.fontM{
    font-size: 22px;
    font-weight: 900;
}
.fontS{
    font-size: 16px;
}
.fontXS{
    font-size: 12px;
}

}


