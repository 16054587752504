.description {
  text-align: justify;
  margin: 20px;
}

//Screen para móviles
@media screen and (max-width: 767px) {
  .imgBody {
    background-color: rgba(203, 202, 202, 0.27);
  }

  .imgIgnoreMovil {
    display: none;
  }
}

//Screen para ordenadores
@media screen and (min-width: 768px) {
  .imgIgnorePC {
    display: none;
  }
}

.imgBody {
  border-radius: 2px;
  height: 200px;
  background-size:cover;
  background-position:center;
  margin: 20px;
  background-repeat: no-repeat;

}

.imgDevelop {
  background-image: url("../../../img/js.jpg");
}

.imgDesign {
  background-image: url("../../../img/responsive.jpg");
}

.imgMainte {
  background-image: url("../../../img/mantenimiento.jpg");
}

.imgSeo {
  background-image: url("../../../img/seo.jpg");
}

.titleSection {
  text-align: left;
  background-color: rgba(0, 0, 0, 0.123);
  padding: 10px;
}

.bodyPresentation {
  height: auto;
  text-align: center;
}

.titleSectionRight {
  text-align: right;
}
