.sectionForm{
    margin: 50px 0px;
}

.titleContact{
    color:black;
    padding: 2vw;
    text-align: center;
}

.optionsContact{
    margin: 25px 0px;
    text-align: center;
    
}
.colorHidden{
    color: rgb(190, 190, 190);
}
.contactForm{
    margin-top: 50px;
    text-align: center;
}

.sectionFooter{
    overflow: hidden;
}

.footer{
    width: 100vw;
    margin: 10px 0px 0px 0px;

}
.footer > a, .footer > .author {
    margin:0px 20px;
    padding: 5px;
    text-decoration: none;
}
.footer, .footer > a{
    background-color: rgb(28, 28, 28);
    color:white;
    display: inline-block;
    text-align: center;
}