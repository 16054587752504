.buttonWs, .buttonEmail{
    background-color: transparent;
    color:white;
    margin:15px;
    padding: 15px;

}
.buttonEmail{
    background-color: rgba(213, 7, 7, 0.364);
}
.buttonWs{
    background-color: rgba(7, 213, 110, 0.364);
}
.inputMessage{
    background-color: rgba(255, 255, 255, 0.318);
    display: block;
    height: 40px;
    margin: 10px auto;
    width: 80%;
    color: white;
    font-weight: 700;
    letter-spacing: 3px;
    &::placeholder{
        color:white;
        font-weight: 700;
        letter-spacing: 3px;
        border-right: 2px solid white;
        padding: 2px;
        width: fit-content;
        text-align: center;
    }
}
