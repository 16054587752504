.navBackToHome{
    background-color: rgb(212, 212, 212);
    height: 50px;
    text-align: center;
    top: 0;
}
.linkBackToHome{
    position:sticky;
    color:black;
    margin-top: 20px;
    align-self: center;
    background-color: white;
    outline-style: none;
    padding: 10px;
    border: 1px solid black;
}
.bodyPrivacy{
    padding: 50px;
}

